import UnifiedLightboxModal from '@components/unified-lightbox/Lightbox';
import {
	VendorMediaKey,
	openUnifiedLightboxAtom,
} from '@components/unified-lightbox/atoms';
import { isStorefrontLightboxRevampSelector } from '@redux/experiments/selectors/storefront-lightbox-revamp';
import { useAppSelector } from '@redux/hooks';
import { useSetAtom } from 'jotai';
import React, { useState } from 'react';
import { FCWithChildren } from 'types/react-extended';
import Gallery from '../Gallery';
import NavSection from '../nav/components/nav_section';
import NewMobileGallery from './mobile_gallery';
import Styles from './styles.scss';

const PhotosSection: FCWithChildren = ({ children }) => (
	<NavSection navId="navPhotos" className={Styles.photoCarouselContainer}>
		{children}
	</NavSection>
);

export const StorefrontGallery = () => {
	const isMobile = useAppSelector((state) => state.viewport.isMobile);
	const [isUnifiedLightboxModalOpen, setIsUnifiedLightboxModalOpen] =
		useState<boolean>(false);
	const isInLightboxRevampExperiment = useAppSelector(
		isStorefrontLightboxRevampSelector,
	);
	const setOpenUnifiedLightbox = useSetAtom(openUnifiedLightboxAtom);

	if (isMobile) {
		return <NewMobileGallery />;
	}

	const handleOpenUnifiedLightbox = async (
		overview: boolean,
		indexInAllMedia: number,
		filter: VendorMediaKey,
	) => {
		await setOpenUnifiedLightbox({ overview, indexInAllMedia, filter });
		setIsUnifiedLightboxModalOpen(true);
	};

	return (
		<>
			<PhotosSection>
				<Gallery
					openUnifiedLightbox={
						isInLightboxRevampExperiment ? handleOpenUnifiedLightbox : null
					}
				/>
			</PhotosSection>
			{isInLightboxRevampExperiment && isUnifiedLightboxModalOpen && (
				<UnifiedLightboxModal
					entryPoint="Mosaic"
					isModalOpen={isUnifiedLightboxModalOpen}
					closeCallback={() => setIsUnifiedLightboxModalOpen(false)}
				/>
			)}
		</>
	);
};

export default StorefrontGallery;
